import React from "react";

const Support = () => {
    return (
        <div>
            <h1>support.</h1>
        </div>
    );
};

export default Support;
